<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Relatório de Contatos</v-toolbar-title>
                    <v-spacer/>
                    <v-spacer/>
                    <PeriodoFilter
                        v-model="periodo"
                        @update="consultar"
                    />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-card class="mx-auto" elevation="4" outlined>
                                    <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                        Ramos
                                    </div>
                                    <canvas id="ramos" :height="pieChartHeight"/>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-card class="mx-auto" elevation="4" outlined>
                                    <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                        Pedidos
                                    </div>
                                    <canvas id="pedidos" :height="pieChartHeight"/>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import PeriodoFilter from '@/components/PeriodoFilter';
import { getColors } from '@/utils/colors';
import Chart from 'chart.js';

export default {
    name: 'RelatorioContatos',

    components: {
        BaseContainer,
        PeriodoFilter,
    },

    data: () => ({
        loading: false,
        dados: {},
        periodo: {},
        pieCharts: {},
    }),

    mounted() {
        this.mountCharts();
        this.consultar();
    },

    computed: {
        pieChartHeight() {
            const w = this.$vuetify.breakpoint.width;
            if (w <= 576) {
                return '400';
            }
            if (w > 576 && w <= 768) {
                return '300';
            }
            if (w > 768 && w <= 992) {
                return '250';
            }
            if (w > 992) {
                return '200';
            }
            return '200';
        },
    },

    methods: {
        consultar() {               
            const start = this.moment(this.periodo.start).format('YYYY-MM-DD HH:mm');
            const end = this.moment(this.periodo.end).format('YYYY-MM-DD HH:mm');

            const filter = `start=${start}&end=${end}`;

            this.loading = true;
            this.$http.post(`contatos/estatisticas?${filter}`).then(resp => {
                this.dados = resp.data.data;
                this.gerarGrafico();
            }).catch(e => {
                console.log(e)
            }).finally(() => (this.loading = false));
        },

        gerarGrafico() {
            const ramos = this.dados.ramos.filter(e => e.ramo);
            const pedidos = this.dados.pedidos.filter(e => e.pedidos);

            this.pieCharts.ramos.data = {
                labels: ramos.map(p => p.ramo),
                datasets: [
                    {
                        data: ramos.map(p => p.total),
                        backgroundColor: this.getColors(),
                    }
                ]
            };
            this.pieCharts.ramos.update();

            this.pieCharts.pedidos.data = {
                labels: pedidos.map(p => p.pedidos),
                datasets: [
                    {
                        data: pedidos.map(p => p.total),
                        backgroundColor: this.getColors(),
                    }
                ]
            };
            this.pieCharts.pedidos.update();
        },

        mountCharts() {
            // TODO: Ajustar para declarar pieCharts em loop
            const common = { type: 'pie', data: { labels: [], datasets: [] }};
            const $ = el => document.getElementById(el);
            this.pieCharts.ramos = new Chart($('ramos'), {
                ...common,
                options: this.getPieChartOptions()
            });

            this.pieCharts.pedidos = new Chart($('pedidos'), {
                ...common,
                options: this.getPieChartOptions()
            });
        },

        getColors() {
            return getColors();
        },

         getPieChartOptions(formatPrice = false) {
            return {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    position: this.$vuetify.breakpoint.mdAndDown ? 'top' : 'right',
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            const label = data.labels[tooltipItem.index];
                            if (formatPrice) {
                                return 'R$ ' + this.formatPrice(value);
                            }
                            return label + ' - ' + value;
                        }
                    }
                }
            }
        },
    },
}
</script>
