const colors = [
    '#E74C3C',
    '#01579B',
    '#00FF66',
    '#FFC107',
    '#dd5182',
    '#d40000',
    '#5D4037',
    '#955196',
    '#00BCD4',
    '#2ECC71',
    '#ff6e54',
    '#ffa610',
    '#673AB7',
    '#34495E',
    '#E72C3C',
    '#8E44AD',
];


export const getColors = () => colors;

export const getSortedColors = () => {
    const colorPalette = JSON.parse(JSON.stringify(colors));
    let currentIndex = colorPalette.length,  randomIndex;

    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [colorPalette[currentIndex], colorPalette[randomIndex]] = [
        colorPalette[randomIndex], colorPalette[currentIndex]];
    }

    return colorPalette;
}

export const getRandomColor = () => {
    return getSortedColors().pop();
}

export default colors;